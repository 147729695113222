import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import './Gradient.scss'

export const BUTTON_TYPES = {
    outLinedPrimary: 'outLinedPrimary',
    outLinedWhite: 'outLinedWhite',
    solidPrimary: 'solidPrimary',
    solidWhite: 'solidWhite',
    gradient: 'gradient',
    redGradient: 'redGradient',
    light: 'light',
    lightRed: 'lightRed'
}

/**
 *
 * @param {{
 * title: string,
 * action: Function,
 * type?: string,
 * hoverType?: string,
 * disabled?: boolean,
 * className?: string,
 * buttonType?: string
 * }} Props
 * @returns
 */
const GradientBtn = ({
    title,
    action,
    type,
    hoverType = BUTTON_TYPES.outLinedPrimary,
    disabled = false,
    className,
    buttonType
}) => {
    const handleClick = () => {
        if (action) {
            action()
        }
    }
    return (
        <Button
            className={`gradient-btn-container ${type} hover-${hoverType} ${className}`}
            onClick={handleClick}
            disabled={disabled && true}
            type={buttonType}
        >
            {title}
        </Button>
    )
}

export default GradientBtn

GradientBtn.propTypes = {
    title: PropTypes.any,
    action: PropTypes.func,
    type: PropTypes.oneOf(Object.keys(BUTTON_TYPES)),
    hoverType: PropTypes.oneOf(Object.keys(BUTTON_TYPES)),
    disabled: PropTypes.bool
}
