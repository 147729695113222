export const baseURL = process.env.REACT_APP_API_LINK
export const isProduction = process.env.REACT_APP_IS_PRODUCTION === 'true'
console.log('isProduction', isProduction)
export const packageName = process.env.REACT_APP_PACKAGE_NAME
export const appId = process.env.REACT_APP_APP_ID

export const egGovEng = [
    'Cairo',
    'Alexandria',
    'Port Said',
    'Suez',
    'Damietta',
    'Dakahlia',
    'Ismeilia',
    'Sharkia',
    'Qalyubia',
    'Kafr ElSheikh',
    'Garbia',
    'Monufia',
    'Benisuif',
    'Aswan',
    'Luxor',
    'Asyut',
    'Behira',
    'Giza',
    'Faiyum',
    'Minya',
    'Sohag',
    'Qena',
    'Red Sea',
    'Matrouh',
    'New Valley',
    'North Sinai',
    'South Sinai'
]

export const egGovAr = [
    'القاهرة',
    'الاسكندرية',
    'بورسعيد',
    'السويس',
    'دمياط',
    'الدقهلية',
    'الإسماعيلية',
    'الشرقية',
    'القليوبية',
    'كفر الشيخ',
    'الغربية',
    'المنوفية',
    'بني سويف',
    'أسوان',
    'الأقصر',
    'أسيوط',
    'البحيرة',
    'الجيزة',
    'الفيوم',
    'المنيا',
    'سوهاج',
    'قنا',
    'البحر الاحمر ',
    'مرسى مطروح',
    'الوادي الجديد',
    'شمال سيناء',
    'جنوب سيناء'
]

export const NamePatern = ' /^[(\u0621-\u064Aa-zA-Z]+$/'
export const DatePatern =
    '  /^(19|20)[0-9][0-9][-\\/. ](0[1-9]|1[012])[-\\/. ](0[1-9]|[12][0-9]|3[01])$/'
export const ArabicNamePatern = ' /^[\u0621-\u064A]+$/'

export const getDrLevelEn = {
    GENERAL_PRACTITIONER: 'General Practitioner',
    SPECIALIST: 'Specialist',
    ASSISTANT_LECTURER: 'Assistant Lecturer',
    CONSULTANT: 'Consultant',
    LECTURER: 'Lecturer',
    ASSISTANT_PROFESSOR: 'Assistant professor',
    PROFESSOR: 'Professor'
}
export const getDrLevelAr = {
    GENERAL_PRACTITIONER: 'ممارس عام',
    SPECIALIST: 'أخصائى',
    ASSISTANT_LECTURER: 'محاضر مساعد',
    CONSULTANT: 'استشارى',
    LECTURER: 'محاضر',
    ASSISTANT_PROFESSOR: 'أستاذ مساعد',
    PROFESSOR: 'أستاذ'
}
