export default class RefreshToken {
    static id = 'refreshToken'

    static getToken() {
        return window.localStorage.getItem(RefreshToken.id)
    }

    static setToken(token) {
        window.localStorage.setItem(RefreshToken.id, token)
    }

    static clearToken() {
        window.localStorage.removeItem(RefreshToken.id)
    }
}
