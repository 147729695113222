import { useCallback, useContext, useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import GradientBtn from '../../Components/gradient-btn/Gradient'
import Loading from '../../Components/loading/Loading'
import SubHeader from '../../Components/sub-header/SubHeader'
import { AuthContext } from '../../context/UserContext'
import useFetch from '../../hooks/useFetch'
import DurationCalender from './duration-calender/DurationCalender'
import styles from './Duration.module.scss'
import DurationsDetailedView from './durations-detailed-view/DurationsDetailedView'
import './DurationTabs.scss'

const Duration = () => {
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const [data, loading, error, execute] = useFetch()
    const durationsData = data?.results || []
    console.log('Get durations err:', error)

    // Get current tab
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const tab = queryParams.get('tab')
    const currentTab = tab === 'Details' ? 'Details' : 'Calender'

    const getDurations = useCallback(() => {
        execute('GET', `v2/doctor/durations/${user.uid}`)
    }, [execute, user.uid])

    useEffect(() => {
        getDurations()
    }, [getDurations])

    if (loading) {
        return <Loading />
    }

    if (!durationsData) {
        return <></>
    }

    return (
        <div className={styles.container}>
            <div className={styles['header-container']}>
                <a
                    data-for='main'
                    data-tip='You can add here your durations '
                    data-iscapture='true'
                >
                    <SubHeader title='Durations' className={styles.title} />
                </a>
                <GradientBtn
                    title='Add New'
                    className='btn'
                    action={() => history.push('/AddDuration')}
                />
            </div>

            <div className={'duration-tabs-container'}>
                <Tabs
                    defaultActiveKey={currentTab}
                    id='uncontrolled-tab-example'
                    className='mb-3'
                    onSelect={(t) => history.replace('/Durations?tab=' + t)}
                >
                    <Tab eventKey='Calender' title='Calender'>
                        {currentTab === 'Calender' && <DurationCalender data={durationsData} />}
                    </Tab>
                    <Tab eventKey='Details' title='Details'>
                        {currentTab === 'Details' && (
                            <DurationsDetailedView
                                data={durationsData}
                                getDurations={getDurations}
                            />
                        )}
                    </Tab>
                </Tabs>
            </div>

            <ReactTooltip
                id='main'
                place={'bottom'}
                type={'info'}
                effect={'float'}
                multiline={true}
            />
        </div>
    )
}

export default Duration
