import React from 'react'
import moment from 'moment'
import Days from './days/Days'
import styles from './DurationsDetailedView.module.scss'

const DurationsDetailedView = ({ data, getDurations }) => {
    const daysDurations = formatDurations(data).days

    return (
        <div className={styles.container}>
            {daysDurations.map((day, i) => (
                <Days key={`day-${i}`} day={day} index={i} getDurations={getDurations} />
            ))}
        </div>
    )
}

export default DurationsDetailedView

function formatDurations(results) {
    /**
     * @type {{item, start: moment.Moment, end: moment.Moment}[]}
     */
    const arr = []

    results.forEach((element) => {
        const { date: start, formatted: startDate } = getLocalDateTime(element.day, element.start)
        const { date: end, formatted: endDate } = getLocalDateTime(element.day, element.end)

        if (startDate === endDate) {
            arr.push({ item: element, start: start, end: end })
        } else {
            arr.push({ item: element, start: start, end: moment(start).endOf('day') })
            arr.push({ item: element, start: moment(end).startOf('day'), end: end })
        }
    })

    /**
     * @type {{item, start: moment.Moment, end: moment.Moment}[][]}
     */
    const days = [[], [], [], [], [], [], []]
    arr.forEach((element) => {
        const day = element.start.get('day')

        days[day].push(element)
    })

    for (let i = 0; i < days.length; i++) {
        sortDay(days[i])
    }

    return { arr, days }
}

/**
 * Bubble sort algorithm
 * @param {{item, start: moment.Moment, end: moment.Moment}[]} day
 */
function sortDay(day) {
    let i, j
    for (i = 0; i < day.length - 1; i++) {
        for (j = 0; j < day.length - i - 1; j++) {
            if (day[j].start > day[j + 1].start) {
                swap(day, j, j + 1)
            }
        }
    }
}

function swap(arr, xp, yp) {
    const temp = arr[xp]
    arr[xp] = arr[yp]
    arr[yp] = temp
}

function getLocalDateTime(day, time) {
    const date = moment.utc(time, 'HH:mm:ssZ').day(day).local().week(moment().get('week'))
    const formatted = date.format('YYYY-MM-DD')

    return { date, formatted }
}
