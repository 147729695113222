import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import './App.scss'
import Loading from './Components/loading/Loading'
import firebaseApp from './constants/firebaseApp'
import { AuthContext } from './context/UserContext'
import useFetch from './hooks/useFetch'
import RefreshToken from './local-storage/RefreshToken'
import backendConfig from './Models/backendConfig'
import ApprovedUserRouter from './routers/approvedUserRouter'
import NotApprovedUserRouter from './routers/notApprovedUserRouter'
import PublicRouter from './routers/public'

const initialPath = window.location.pathname

function App() {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(true)
    const [doctorData, doctorLoading, doctorError, executeDoctor] = useFetch()

    const userContext = useContext(AuthContext)
    const { setUser, setSecretary, user, setAuthToken } = userContext

    /**
     * Handle user state changes
     */
    useEffect(() => {
        if (doctorData) {
            return
        }

        moment.locale('en')
        const secretaryRefreshToken = RefreshToken.getToken()

        if (secretaryRefreshToken === null) {
            firebaseApp.auth().onAuthStateChanged(async (firebaseUser) => {
                try {
                    if (firebaseUser && firebaseUser.emailVerified === true) {
                        backendConfig.updateAuthToken(await firebaseUser.getIdToken(false))
                        executeDoctor('get', `v2/doctor/profile/${firebaseUser.uid}`)
                    } else if (firebaseUser) {
                        setLoading(false)
                        await firebaseUser
                            .sendEmailVerification()
                            .then(() => firebaseApp.auth().signOut())
                    } else {
                        setUser(undefined)
                        setLoading(false)
                    }
                } catch (err) {
                    console.log('onAuthStateChanged err: ', err)
                }
            })
        } else {
            console.log('token', secretaryRefreshToken)

            executeDoctor('post', 'v2/secretary/auth/refresh', { token: secretaryRefreshToken })
        }
    }, [doctorData, executeDoctor, setUser])

    /**
     * Handle auth token changes
     * For doctors only (Not secretaries)
     */
    useEffect(() => {
        const secretaryRefreshToken = RefreshToken.getToken()

        if (secretaryRefreshToken !== null) {
            return
        }

        firebaseApp.auth().onIdTokenChanged(async (firebaseUser) => {
            // If there is no doctor logged in ... remove the token
            if (firebaseUser === null) {
                backendConfig.updateAuthToken('')
            } else {
                backendConfig.updateAuthToken(await firebaseUser.getIdToken(false))
            }
        })
    }, [doctorData, executeDoctor, setUser])

    useEffect(() => {
        if (doctorData) {
            const { secretary } = doctorData

            // Doctor logged in
            if (secretary === undefined) {
                const { results } = doctorData
                setUser(results)

                // secretary logged in
            } else {
                const { authToken, refreshToken, user: doctor } = doctorData

                if (doctor.approved !== 1 || secretary.active !== 1) {
                    setSecretary(undefined)
                    setAuthToken(undefined)
                    setUser(undefined)
                    firebaseApp.auth().signOut()
                    window.location.reload()
                } else {
                    backendConfig.updateAuthToken(authToken)
                    setSecretary(secretary)
                    setAuthToken(authToken)
                    setUser(doctor)

                    RefreshToken.setToken(refreshToken)
                }
            }
            setLoading(false)
        }
    }, [doctorData, setAuthToken, setSecretary, setUser])

    useEffect(() => {
        if (doctorError) {
            console.log('doctorError in get doctor profile: ', doctorError)
            setLoading(false)
        }
    }, [doctorError])

    if (loading || doctorLoading) {
        return <Loading />
    }

    // Not logged in
    if (!user) {
        return <PublicRouter initialPath={initialPath} />
    }

    // Not approved users
    if (user.approved !== 1) {
        return <NotApprovedUserRouter initialPath={initialPath} show={show} />
    }

    // Approved users
    return <ApprovedUserRouter initialPath={initialPath} show={show} setShow={setShow} />
}

export default App
