import React from 'react'
import PropTypes from 'prop-types'
import styles from './SubHeader.module.scss'

const SubHeader = ({ title, titleRef = null }) => {
    SubHeader.propTypes = {
        title: PropTypes.string
    }
    return (
        <h1 className={styles.header} ref={titleRef}>
            {title}
        </h1>
    )
}

export default SubHeader
