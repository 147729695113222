import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router'
import moment from 'moment'
import { AuthContext } from '../../../context/UserContext'
import useFetch from '../../../hooks/useFetch'

import styles from './BlogDescription.module.scss'
import GradientBtn from '../../../Components/gradient-btn/Gradient'
import Loading from '../../../Components/loading/Loading'
import firebaseApp from '../../../constants/firebaseApp'
import replaceURLs from '../../../helpers/urlReplacer'
// import Parser from 'html-react-parser'

const BlogDescription = () => {
    const { blogId } = useParams()
    const { user } = useContext(AuthContext)
    const [data, loading, error, execute] = useFetch()
    const [deleteData, loadingDelete, deleteError, executeDelete] = useFetch()
    const [pinData, loadingPin, errorPin, executePin] = useFetch()

    const html = replaceURLs(data && data.data.content)

    useEffect(() => {
        const analytics = firebaseApp.analytics()
        analytics.screenView(analytics.screens.blogDescription)
    }, [])

    /**
     * GET DATA FIRST TIME
     */
    useEffect(() => {
        execute('get', `v2/patient/blogs/${blogId}?doctorUid=${user.uid}`)
    }, [blogId, execute, user.uid])

    useEffect(() => {
        if (data) {
            console.log('data---->', data)
        }
    }, [data])

    useEffect(() => {
        if (error) {
            console.log('error: ', error)
        }
    }, [error])

    /**
     * Delete Blog
     */
    const deleteBlog = () => {
        executeDelete('DELETE', `v2/doctor/blogs/${user.uid}/${blogId}`)
    }

    useEffect(() => {
        if (deleteData) {
            console.log('deleteData: ', deleteData)
            history.back()
        }
    }, [deleteData])

    useEffect(() => {
        if (deleteError) {
            console.log('deleteError: ', deleteError)
        }
    }, [deleteError])

    /**
     * PIN BLOG
     */
    const pinBlog = () => {
        executePin('PUT', `v2/doctor/blogs/pin/${user.uid}/${blogId}`, {
            pinned: !data.data.pinned
        })
    }
    useEffect(() => {
        if (pinData) {
            console.log('pinData: ', pinData)
            history.back()
        }
    }, [pinData])

    useEffect(() => {
        if (errorPin) {
            console.log('errorPin: ', errorPin)
        }
    }, [errorPin])

    useEffect(() => {
        if (data) {
            console.log('data', data)
        }
    }, [data])

    useEffect(() => {
        if (error) {
            console.log('error: ', error)
        }
    }, [error])

    if (!data) {
        return <></>
    }
    if (loading) {
        return <Loading />
    }

    return (
        <div className={styles['blog-des-container']}>
            <img
                className={styles['card-des-img']}
                src={data.data.image || '/assets/blogStaticImg.png'}
                alt='blog image'
            />
            <p className={styles['card-des-time']}>
                {moment(data.data.postTime * 1000).format('ddd D MMMM YYYY, hh:mm A')}
            </p>
            <span className={styles['card-des-title']}>{data.data.title}</span>

            <p className={styles['card-des-des']} dangerouslySetInnerHTML={{ __html: html }}>
                {/* {replaceURLs(data.data.content)} */}
            </p>
            <div className={styles['btns-container']}>
                <GradientBtn
                    type='redGradient'
                    hoverType='lightRed'
                    action={deleteBlog}
                    disabled={loadingDelete}
                    title={loadingDelete ? 'Loading' : 'Delete'}
                    className={styles['delete-btn']}
                />
                <GradientBtn
                    action={pinBlog}
                    disabled={loadingPin}
                    title={loadingPin ? 'Loading' : data.data.pinned ? 'Unpin post' : 'Pin post'}
                    className={styles['pin-btn']}
                />
            </div>
        </div>
    )
}
export default BlogDescription
