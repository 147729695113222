import React, { useState } from 'react'

/**
 * @type {{
 * user?: {uid, firstName, lastName};
 * setUser: (u: any) => void;
 * secretary?: {};
 * setSecretary: (s: any) => void;
 * authToken?: {};
 * setAuthToken: (a: any) => void;
 * }}
 */
const defaultContext = {
    user: undefined,
    setUser: (u) => console.log(u),
    secretary: undefined,
    setSecretary: (s) => console.log(s),
    authToken: undefined,
    setAuthToken: (a) => console.log(a)
}
export const AuthContext = React.createContext(defaultContext)

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(undefined)
    const [secretary, setSecretary] = useState(undefined)
    const [authToken, setAuthToken] = useState(undefined)

    return (
        <AuthContext.Provider
            value={{ user, setUser, secretary, setSecretary, authToken, setAuthToken }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider
