import React, { Suspense, useState, lazy } from 'react'
import { HamburgerButton } from 'react-hamburger-button'
import { Redirect, Route, Switch } from 'react-router-dom'

import Loading from '../Components/loading/Loading'
import '../App.scss'
const SideBar = lazy(() => import('../Components/side-bar/SideBar'))
const NavBar = lazy(() => import('../Components/nav-bar/NavBar'))

type Props = {
    children: React.ReactNode
    renderNavBar?: boolean
    renderSideBar?: boolean
    initialPath: string
}

const CommonRouter = ({ children, renderNavBar, renderSideBar, initialPath }: Props) => {
    const [show, setShow] = useState(false)

    return (
        <Suspense fallback={<Loading />}>
            <div className='App'>
                {renderNavBar && (
                    <div className='navbar-section'>
                        <div className='hamburger'>
                            {renderSideBar && (
                                <HamburgerButton
                                    onClick={show ? () => {} : () => setShow(true)}
                                    width={20}
                                    height={20}
                                    strokeWidth={4}
                                    color='#31C2E1'
                                    animationDuration={0.5}
                                    open={show}
                                />
                            )}
                        </div>

                        <NavBar />
                    </div>
                )}
                <div className={`main-page ${show ? 'show' : ''}`}>
                    {renderSideBar && <SideBar show={show} setShow={setShow} />}

                    <div className={`main-page-content ${show ? 'show' : ''}`}>
                        <Switch>
                            {children}
                            <Route component={() => <Redirect to='/' />} />
                            <Redirect to={initialPath} />
                        </Switch>
                    </div>
                </div>
            </div>
        </Suspense>
    )
}

export default React.memo(CommonRouter)
