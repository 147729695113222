import { useCallback, useContext, useState } from 'react'
import axios, { Method } from 'axios'
import firebaseApp from '../constants/firebaseApp'
import { baseURL } from '../constants/constants'
import { AuthContext } from '../context/UserContext'

function useFetch() {
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>(null)
    const { authToken } = useContext(AuthContext)

    const execute = useCallback(
        async (method: Method, url: string, body?: any) => {
            setLoading(true)
            setData(null)
            setError(null)

            const headers = { 'Content-Type': 'application/json', 'auth-token': '' }

            const fbUser = firebaseApp.auth().currentUser
            if (fbUser) {
                headers['auth-token'] = await fbUser.getIdToken()
            } else if (authToken) {
                headers['auth-token'] = authToken as string
            }

            return new Promise((resolve, reject) => {
                axios({ url: `${baseURL}/${url}`, method, data: body, headers })
                    .then((res) => {
                        const { data: d } = res
                        const result = d ? d : res

                        setData(result)
                        resolve(result)
                    })
                    .catch((err) => {
                        let errors = err

                        if (err.response && err.response.data) {
                            errors = err.response.data
                        } else if (err.response) {
                            errors = err.response
                        }

                        setError(errors)
                        reject(errors)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
        },
        [authToken]
    )

    const reset = useCallback(() => {
        setData(null)
        setError(null)
    }, [])

    return [data, loading, error, execute, reset] as [
        any,
        boolean,
        any,
        (method: any, url: any, body?: any) => Promise<void>,
        () => void
    ]
}

export default useFetch
