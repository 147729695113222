import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import Event, { EventWrapper } from './DurationEvent'
import './DurationCalender.scss'

const currentDate = moment().toDate()

const DurationCalender = ({ data }) => {
    const localizer = momentLocalizer(moment)
    const events = formatDurations(data)

    return (
        <div className='calender-content'>
            <Calendar
                className='calender'
                localizer={localizer}
                startAccessor='start'
                endAccessor='end'
                defaultView='week'
                step='15'
                views={['week']}
                scrollToTime={currentDate}
                components={{
                    event: (item) => <Event event={item.event} status={item.event.item.active} />,
                    header: (day) => <Day day={day} />,
                    eventWrapper: EventWrapper
                }}
                events={events}
            />
        </div>
    )
}

export default DurationCalender

const Day = ({ day }) => <span>{moment(day.date).format('dddd')}</span>

function formatDurations(results) {
    const arr = []

    results.forEach((element) => {
        const startDate = moment
            .utc(element.start, 'HH:mm:ssZ')
            .day(element.day)
            .local()
            .week(moment().get('week'))

        const endDate = moment
            .utc(element.end, 'HH:mm:ssZ')
            .day(element.day)
            .local()
            .week(moment().get('week'))

        const fromDate = startDate.format('YYYY-MM-DD')
        const toDate = endDate.format('YYYY-MM-DD')

        if (fromDate === toDate) {
            arr.push({
                item: element,
                allDay: false,
                start: startDate.toDate(),
                end: endDate.toDate()
            })
        } else {
            arr.push({
                item: element,
                allDay: false,
                start: startDate.toDate(),
                end: moment(startDate).endOf('day').toDate()
            })
            arr.push({
                item: element,
                allDay: false,
                start: moment(endDate).startOf('day').toDate(),
                end: endDate.toDate()
            })
        }
    })

    return arr
}
