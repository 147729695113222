import './Loading.scss'

import Lottie from 'lottie-react'
import groovyWalkAnimation from '../../constants/animation_kxsxv7h0.json'

const Loading = () => (
    <div className='loader-container'>
        <Lottie animationData={groovyWalkAnimation} className='loader' />
    </div>
)

export default Loading
