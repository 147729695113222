import moment from 'moment'
import { useState } from 'react'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput'
import { useHistory } from 'react-router-dom'
import DurationModal from '../../duration-details/duration-modal/DurationModal'
import styles from './Days.module.scss'

/**
 *
 * @param {{
 *  index: number,
 *  day: {item, start: moment.Moment, end: moment.Moment}[],
 * getDurations: Function
 * }} props
 * @returns
 */
const Days = ({ day, index, getDurations }) => {
    const durationLength = day.length

    if (durationLength === 0) {
        return (
            <div className={styles.container}>
                <h4>{moment().set('day', index).format('dddd')}</h4>

                <p className={styles.empty}>No durations in this day</p>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <h4>{moment().set('day', index).format('dddd')}</h4>

            {day.map((element, i) => (
                <Day
                    key={`day-${index}-duration-${i}`}
                    element={element}
                    index={i}
                    durationLength={durationLength}
                    getDurations={getDurations}
                />
            ))}
        </div>
    )
}
export default Days

const Day = ({ element, index, durationLength, getDurations }) => {
    const { item, start, end } = element
    const { id, active, type, clinic, disabledWeeks } = item
    const history = useHistory()
    const [modalWeek, setModalWeek] = useState()

    const showLineBreak = index < durationLength - 1
    const weeks = getWeeks(disabledWeeks)
    return (
        <div
            className={`
                ${styles.duration} 
                ${showLineBreak ? styles['line-break'] : ''} 
                ${active === 0 ? styles.deactivated : ''}`}
        >
            <div
                className={styles['duration-head']}
                onClick={() => history.push(`/duration-details/${id}`)}
            >
                <h6>
                    {start.format('hh:mmA')} - {end.format('hh:mmA')}
                </h6>
                <p>{getClinicName(type, clinic)}</p>
            </div>

            <div className={styles.weeks}>
                {weeks.map((week, i) => (
                    <div key={`duration-${id}-week-${i}`} className={styles.week}>
                        <p className={styles.title}>{formatDate(week.date, element.item.day, i)}</p>
                        <FormCheckInput
                            className={styles.check}
                            checked={week.isChecked}
                            onChange={() => setModalWeek(week)}
                        />
                    </div>
                ))}
            </div>
            {modalWeek && (
                <DurationModal
                    type={'WeekStatus'}
                    closeModal={() => setModalWeek()}
                    durationId={id}
                    isActive={active}
                    reRender={getDurations}
                    week={modalWeek}
                />
            )}
        </div>
    )
}

function formatDate(date, day) {
    const today = moment().isoWeekday()
    // if we haven't yet passed the day of the week that I need:
    if (today < day) {
        // then just give me this week's instance of that day
        return moment(date, 'YYYY-MM-DD').set('day', day).format('D/M')
    } else {
        // otherwise, give me *next week's* instance of that same day
        return moment(date, 'YYYY-MM-DD').add(1, 'weeks').set('day', day).format('D/M')
    }
}

function getWeeks(disabledWeeks) {
    const weeks = []
    let weekIndex = 0
    for (let i = 0; i < 4; i++) {
        const date = moment().set('days', 6)
        if (moment().isAfter(date)) {
            weekIndex++
        }
        const weekStart = date.add(weekIndex, 'week').format('YYYY-MM-DD')
        let isChecked = true
        let weekId
        disabledWeeks.forEach((week) => {
            const isDisabled = moment(week.weekStartDate).format('YYYY-MM-DD') === weekStart
            if (isDisabled) {
                isChecked = false
                weekId = week.weekId
            }
        })
        weekIndex++
        weeks.push({ date: weekStart, isChecked, weekId })
    }
    return weeks
}

function getClinicName(type, clinicName) {
    let clinic = 'Online'
    if (type === 'BOTH') {
        clinic = `${clinicName} and Online`
    } else if (type === 'CLINIC') {
        clinic = clinicName
    }

    return clinic
}
