import { lazy } from 'react'
import { Route } from 'react-router-dom'
import Duration from '../Pages/duration/Duration'

import BlogDescription from '../Pages/blogs/blog-card-des/BlogDescription'
import CommonRouter from './common'
import { PATHS } from '../constants/paths'
const DurationDetails = lazy(() => import('../Pages/duration/duration-details/DurationDetails.jsx'))
const AddDuration = lazy(() => import('../Pages/duration/add-new-duration/AddDuration'))
const AddService = lazy(() => import('../account-information/services/add-service/AddService'))
const AccountInformation = lazy(() => import('../account-information/AccountInformation'))
const AddSecretary = lazy(
    () => import('../account-information/secretary/add-secretary/AddSecretary')
)
const EditService = lazy(() => import('../account-information/services/edit-service/EditService'))
const AddServiceImage = lazy(
    () => import('../account-information/services/add-service-image/AddServiceImage')
)
const AddClinic = lazy(() => import('../account-information/clinics/add-clinic/AddClinic'))
const EditClinic = lazy(() => import('../account-information/clinics/edit-clinic/EditClinic'))

const ClinicDetails = lazy(
    () => import('../account-information/clinics/clinic-details/ClinicDetails')
)
const Terms = lazy(() => import('../account-information/legal-terms/terms'))
const ContactUs = lazy(() => import('../contact-us/ContactUs'))
const Subscription = lazy(() => import('../Pages/subscription/Subscription'))
const Appointments = lazy(() => import('../Pages/appointments/Appointments'))
const Blogs = lazy(() => import('../Pages/blogs/Blogs'))
const AddNewBlog = lazy(() => import('../Pages/blogs/add-new-blog/AddNewBlog'))
const PaymentHistory = lazy(() => import('../Pages/subscription/PaymentHistory'))
const ServiceDetails = lazy(
    () => import('../account-information/services/service-details/ServiceDetails')
)
const Favorites = lazy(() => import('../favorites/Favorites-layout'))
const ViewMedicalRecord = lazy(
    () => import('../Pages/medical-record/view-medical-record/ViewMedicalRecord')
)
const AddMedicalRecord = lazy(() => import('../Pages/medical-record/add-medical-record/AddRecord'))
const BookNew = lazy(() => import('../Pages/appointments/book-new/BookNew'))
const RegisterPatient = lazy(() => import('../Pages/register-patient/RegisterPatient'))
const PatientProfile = lazy(() => import('../Pages/patients/patient-profile/PatientProfile'))
const PatientsList = lazy(() => import('../Pages/patients/patients-list/PatientsList'))
const AddPromoCode = lazy(() => import('../promo-code/add-promo-code/AddPromoCode'))
const PromoCode = lazy(() => import('../promo-code/PromoCode'))
const Earnings = lazy(() => import('../Pages/earnings/Earnings'))
const ChatListController = lazy(() => import('../controllers/ChatListController'))

type approvedRouterProps = {
    initialPath: string
}

const ApprovedUserRouter = ({ initialPath }: approvedRouterProps) => (
    <CommonRouter initialPath={initialPath} renderNavBar renderSideBar>
        <Route exact path={PATHS.HOME} component={Appointments} />

        {/* Blogs */}
        <Route exact path={PATHS.BLOGS} component={Blogs} />
        <Route exact path={PATHS.ADD_NEW_BLOG} component={AddNewBlog} />
        <Route path={PATHS.BLOG_DETAILS} component={BlogDescription} />

        {/* Durations */}
        <Route exact path={PATHS.DURATIONS} component={Duration} />
        <Route exact path={PATHS.ADD_DURATION} component={AddDuration} />
        <Route exact path={PATHS.DURATION_DETAILS} component={DurationDetails} />

        {/* Chat */}
        <Route exact path={PATHS.MESSAGE} component={ChatListController} />

        {/* Earnings */}
        <Route exact path={PATHS.EARNINGS} component={Earnings} />

        {/* PromoCodes */}
        <Route exact path={PATHS.PROMO_CODE} component={PromoCode} />
        <Route exact path={PATHS.ADD_PROMO_CODE} component={AddPromoCode} />

        {/* PatientList */}
        <Route exact path={PATHS.PATIENTS_LIST} component={PatientsList} />
        <Route exact path={PATHS.PATIENT_DETAILS} component={PatientProfile} />
        <Route exact path={PATHS.REGISTER_PATIENT} component={RegisterPatient} />
        <Route exact path={PATHS.BOOK_NEW} component={BookNew} />

        {/* MedicalRecord */}
        <Route exact path={PATHS.ADD_MEDICAL_RECORD} component={AddMedicalRecord} />
        <Route exact path={PATHS.VIEW_MEDICAL_RECORD} component={ViewMedicalRecord} />

        {/* Favorites */}
        <Route exact path={PATHS.FAV} component={Favorites} />

        {/* AccountInformation */}
        <Route exact path={PATHS.ACCOUNTING_INFO} component={AccountInformation} />

        <Route exact path={PATHS.ADD_SERVICE} component={AddService} />
        <Route exact path={PATHS.EDIT_SECRETARY} component={AddSecretary} />
        <Route exact path={PATHS.SERVICE_DETAILS} component={ServiceDetails} />

        <Route exact path={PATHS.EDIT_SERVICE} component={EditService} />
        <Route exact path={PATHS.ADD_SERVICE_IMG} component={AddServiceImage} />
        <Route exact path={PATHS.EDIT_SERVICE_IMG} component={AddServiceImage} />
        <Route exact path={PATHS.ADD_SECRETARY} component={AddSecretary} />
        <Route exact path={PATHS.ADD_CLINIC} component={AddClinic} />
        <Route exact path={PATHS.EDIT_CLINIC} component={EditClinic} />
        <Route exact path={PATHS.CLINIC_DETAILS} component={ClinicDetails} />

        <Route exact path={PATHS.TERMS} component={Terms} />
        <Route exact path={PATHS.TECHNICAL_SUPPORT} component={ContactUs} />
        <Route exact path={PATHS.SUBSCRIPTION} component={Subscription} />
        <Route exact path={PATHS.SUBSCRIPTIONS} component={PaymentHistory} />
    </CommonRouter>
)

export default ApprovedUserRouter
