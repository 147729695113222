import { ModalView } from '@curawella/curawella-components'
import moment from 'moment'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import GradientBtn, { BUTTON_TYPES } from '../../../../Components/gradient-btn/Gradient'
import { AuthContext } from '../../../../context/UserContext'
import useFetch from '../../../../hooks/useFetch'
import styles from './DurationModal.module.scss'

const messages = {
    Status: {
        0: 'Patients will have an access to this duration and can book appointments.',
        1: 'Patients will not be able to book any appointments in this duration, You can delete it if you want to disable it forever.'
    },
    WeekStatus: {
        false: (weekStart) =>
            `Patients will have access to this duration for the week  
            (${weekStart.format('D/M')} - ${weekStart.add(6, 'days').format('D/M')}) 
                and can book appointments.`,
        true: (weekStart) =>
            `Patients will not be able to book any appointments in this duration for the week 
            (${weekStart.format('D/M')} - ${weekStart.add(6, 'days').format('D/M')})
            , You can delete it if you want to disable it forever.`
    },
    Delete: 'Patients will not be able to book an appointments in this duration, You can deactivate it if you want to disable it temporary.'
}

/**
 *
 * @param {{
 * reRender: Function,
 * closeModal: Function,
 * durationId: number,
 * isActive: 0 | 1,
 * type: 'Status'| 'Delete' | 'WeekStatus',
 * week: {weekId: number | undefined, date: string, isChecked: boolean}
 * }} props
 */
const DurationModal = ({ reRender, closeModal, durationId, isActive, type, week }) => {
    const [data, loading, error, execute] = useFetch()
    const { user } = useContext(AuthContext)
    const history = useHistory()

    console.log('week', week)

    const deactivateDuration = () => {
        // Enable/Disable duration
        if (type === 'Status') {
            execute('PUT', `v2/doctor/durations/${user.uid}/${durationId}`, {
                active: isActive === 1 ? false : true
            })

            // Delete duration
        } else if (type === 'Delete') {
            execute('DELETE', `v2/doctor/durations/${user.uid}/${durationId}`)

            // wrong type or data
        } else if (type === 'WeekStatus' && week === undefined) {
            throw Error('wrong modal type or modal data')

            // Enable week
        } else if (type === 'WeekStatus' && week.isChecked === false) {
            execute(
                'DELETE',
                `v2/doctor/durations/${user.uid}/${durationId}/disableWeek/${week.weekId}`
            )

            // Disable week
        } else if (type === 'WeekStatus') {
            execute('POST', `v2/doctor/durations/${user.uid}/${durationId}/disableWeek`, {
                weekStartDate: week.date
            })
        }
    }

    useEffect(() => {
        if (data) {
            closeModal()
            if (type === 'Delete') {
                history.goBack()
            } else {
                reRender()
            }
        }
    }, [data, reRender, closeModal, type, history])

    useEffect(() => {
        if (error) {
            console.log('error: ', error)
        }
    }, [error])

    return (
        <ModalView
            show
            setShow={closeModal}
            type='customView'
            title={getTitle(type, isActive, week?.isChecked)}
            customView={
                <div className={styles.container}>
                    <p className={styles.description}>
                        {getMessage(type, isActive, week?.isChecked, week?.date)}
                    </p>
                    <GradientBtn
                        className={styles.button}
                        title={loading ? 'Loading' : getTitle(type, isActive, week?.isChecked)}
                        type={type === 'Delete' ? BUTTON_TYPES.redGradient : BUTTON_TYPES.gradient}
                        action={deactivateDuration}
                        disabled={loading}
                    />
                </div>
            }
        />
    )
}

function getTitle(type, isActive, isChecked) {
    if (type === 'Status') {
        return isActive === 1 ? 'Deactivate' : 'Activate'
    } else if (type === 'WeekStatus') {
        return isChecked ? 'Deactivate' : 'Activate'
    } else {
        return 'Delete'
    }
}

function getMessage(type, isActive, isChecked, date) {
    if (type === 'Status') {
        return messages.Status[isActive]
    } else if (type === 'WeekStatus') {
        return messages.WeekStatus[isChecked](moment(date, 'YYYY-MM-DD'))
    } else {
        return messages.Delete
    }
}

export default DurationModal
