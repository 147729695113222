import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/database'
import { isProduction } from './constants'

const firebaseConfig = isProduction
    ? {
          apiKey: 'AIzaSyC7YW148SWJn1tqbsoACjMwnbNdCg9F7wY',
          authDomain: 'el3eyada-cdb0f.firebaseapp.com',
          databaseURL: 'https://el3eyada-cdb0f.firebaseio.com',
          projectId: 'el3eyada-cdb0f',
          storageBucket: 'el3eyada-cdb0f.appspot.com',
          messagingSenderId: '801454395931',
          appId: '1:801454395931:web:a8a7de7149d46f4d935ebe',
          measurementId: 'G-MJ8B7CY6RB'
      }
    : {
          apiKey: 'AIzaSyAHPrWPDgtdfg2ihSxwQ6PLYAvS_zd19N8',
          authDomain: 'curawella-development.firebaseapp.com',
          databaseURL: 'https://curawella-development.firebaseio.com',
          projectId: 'curawella-development',
          storageBucket: 'curawella-development.appspot.com',
          messagingSenderId: '434693192696',
          appId: '1:434693192696:web:514f2d6ae6d70acbaef40d',
          measurementId: 'G-F7TZBT0T5L'
      }

class FirebaseConfigs {
    firebaseApp

    constructor() {
        if (!firebase.apps.length) {
            this.firebaseApp = firebase.initializeApp(firebaseConfig)
        } else {
            this.firebaseApp = firebase.app()
        }
    }
    storage() {
        return {
            ...this.firebaseApp.storage(),
            ref: (r) => this.firebaseApp.storage().ref(`${r}`),
            refFromURL: (r) => this.firebaseApp.storage().refFromURL(`${r}`),
            avatar: (uid) => this.firebaseApp.storage().ref(`/Avatar/${uid}`),
            services: (doctorUid, serviceId, timestamp) =>
                this.firebaseApp
                    .storage()
                    .ref(`/operations/${doctorUid}/${serviceId}/${timestamp}`),
            medicalRecord: (patientUid, timestamp = undefined) => {
                const ref = this.firebaseApp.storage().ref(`/MedicalRecord/${patientUid}`)
                if (typeof timestamp !== 'undefined') {
                    return ref.child(timestamp + '')
                } else {
                    return ref
                }
            },
            blog: (doctorUid, timestamp) =>
                this.firebaseApp.storage().ref(`/ArticlesImages/${doctorUid}/${timestamp}`),
            message: (patientUid, timestamp) =>
                this.firebaseApp.storage().ref(`Messages/${patientUid}/${timestamp}`)
        }
    }

    auth() {
        return this.firebaseApp.auth()
    }

    analytics() {
        const analytics = this.firebaseApp.analytics()

        return {
            /**
             *
             * @param {string} screenName
             * @param {string} [screenClass]
             * @returns
             */
            screenView: (screenName, screenClass) =>
                analytics.logEvent('screen_view', {
                    firebase_screen: screenName,
                    firebase_screen_class: screenClass || screenName
                }),
            /**
             *
             * @param {string} event
             * @returns
             */
            logEvent: (event) => analytics.logEvent(event),
            login: () => {
                analytics.logEvent('login', {
                    firebase_screen: analyticsScreens.login,
                    firebase_screen_class: analyticsScreens.login
                })
            },
            screens: analyticsScreens,
            events: analyticsEvents
        }
    }
}

export default new FirebaseConfigs()

const analyticsScreens = {
    login: 'Login',
    signUp: 'SignUp',
    completeProfile: 'Complete Profile',
    resetPassword: 'Reset Password',
    appointment: 'Appointment',
    blogs: 'Blogs',
    addNewBlog: 'Add New Blog',
    blogDescription: ' Blog Description',
    durations: 'Durations',
    addDuration: 'Add Duration',
    messages: 'Messages',
    earnings: 'Earnings',
    promoCode: 'Promo Code',
    addPromoCode: 'Add Promo Code',
    patientsList: 'Patients List',
    registerPatient: 'Register Patient',
    patientProfile: ' patient Profile',
    bookNewAp: 'Book New Appointment',
    bookNewSer: 'Book New Service',
    viewMedicalRecord: 'View Medical Record',
    addMedicalRecord: 'Add Medical Record',
    doctorProfile: 'Edit Doctor Profile',
    online: 'Edit Doctor Online Tab',
    clinics: 'Edit Doctor Clinics',
    addClinic: 'Add Clinic',
    editClinicInfo: 'Edit Clinic information',
    services: 'Edit Doctor Services',
    addServices: 'Add Services',
    editServices: 'Edit Services',
    addServiceImage: 'Add service image',
    editServiceImg: 'Edit Service image',
    secretaries: ' Edit Doctor secretaries',
    addSecretary: 'Add Secretary',
    favorites: 'Favorites',
    contactUs: 'ContactUs',
    Terms: 'Terms & Privacy',
    subscriptions: 'Subscriptions',
    savedCards: 'Saved cards',
    paymentHistory: 'Payment History'
}

const analyticsEvents = {
    notification: 'Notification',
    UserGuide: 'User guide',
    logout: 'Logout',
    CustomID: 'Edit Custom ID'
}
