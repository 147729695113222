import { BackendCall } from 'react-usefetch-models'
import { baseURL } from '../constants/constants'

class Backend {
    config!: BackendCall
    authToken = ''

    constructor() {
        this.initializeBackend('')
    }

    updateAuthToken(authToken: string) {
        this.authToken = authToken
        this.initializeBackend(authToken)
    }

    private initializeBackend(authToken: string) {
        // Default Headers containing headers to be set by default to any request unless it overwritten
        const defaultHeaders = {
            'Content-Type': 'application/json',
            'auth-token': authToken
        }

        if (!baseURL) {
            throw new Error('Backend API NOT FOUND')
        }

        /**
         * Function to be executed after the success and do whatever you want
         * @param {any} res
         */
        async function defaultSuccessFunction(res: any): Promise<any> {
            return new Promise((resolve, reject) => {
                let d
                if (res.success) {
                    if (res.data) {
                        d = res.data
                    } else if (res.results) {
                        d = res.results
                    } else {
                        d = res
                    }
                    resolve(d)
                } else {
                    reject(res.code)
                }
            })
        }

        /**
         * Function to be executed after the catching the error and do whatever you want
         * @param {any} err the error as a first and only parameter
         * @return {any} Whatever u want to be in the rejected state
         */
        async function defaultErrorFunction(err: any): Promise<any> {
            console.log('defaultErrorFunction', err)

            return new Promise((resolve, reject) => {
                // handle bad request "400" errors
                if (typeof err === 'string') {
                    resolve(err)
                } else {
                    reject(err)
                }

                // if (err === 'UNAUTHORIZED') {
                //     modalAlert({
                //         type: 'ERROR',
                //         title: 'Unauthorized',
                //         description:
                //             'You are unauthorized to use this functionality, please check your authorization with your service provider'
                //     })
                // } else if (err === 'SESSION_EXPIRED') {
                //     modalAlert({
                //         type: 'ERROR',
                //         title: 'Session expired',
                //         description: 'Your session has expired and you must login again',
                //         confirmButton: { text: 'Login', action: () => location.reload() }
                //     })
                // }
            })
        }

        this.config = new BackendCall(
            baseURL,
            defaultHeaders,
            defaultSuccessFunction,
            defaultErrorFunction
        )
    }
}

export default new Backend()
