import { lazy } from 'react'
import { Route } from 'react-router-dom'
import Duration from '../Pages/duration/Duration'

import { PATHS } from '../constants/paths'
import CommonRouter from './common'

const ContactUs = lazy(() => import('../contact-us/ContactUs'))
const AddDuration = lazy(() => import('../Pages/duration/add-new-duration/AddDuration'))
const Terms = lazy(() => import('../account-information/legal-terms/terms'))
const EditService = lazy(() => import('../account-information/services/edit-service/EditService'))
const Subscription = lazy(() => import('../Pages/subscription/Subscription'))
const AddService = lazy(() => import('../account-information/services/add-service/AddService'))
const AddServiceImage = lazy(
    () => import('../account-information/services/add-service-image/AddServiceImage')
)
const AddSecretary = lazy(
    () => import('../account-information/secretary/add-secretary/AddSecretary')
)
const EditClinic = lazy(() => import('../account-information/clinics/edit-clinic/EditClinic'))
const AccountInformation = lazy(() => import('../account-information/AccountInformation'))
const AddClinic = lazy(() => import('../account-information/clinics/add-clinic/AddClinic'))
const ClinicDetails = lazy(
    () => import('../account-information/clinics/clinic-details/ClinicDetails')
)
const DurationDetails = lazy(
    () => import('../account-information/clinics/clinic-details/DurationDetails')
)
const ServiceDetails = lazy(
    () => import('../account-information/services/service-details/ServiceDetails')
)

type NotApprovedRouterProps = {
    initialPath: string
}

const NotApprovedUserRouter = ({ initialPath }: NotApprovedRouterProps) => (
    <CommonRouter initialPath={initialPath} renderNavBar>
        <Route exact path={PATHS.DURATIONS} component={Duration} />
        <Route exact path={PATHS.ADD_DURATION} component={AddDuration} />
        <Route exact path={PATHS.DURATION_DETAILS} component={DurationDetails} />

        <Route exact path={PATHS.ACCOUNTING_INFO} component={AccountInformation} />

        <Route exact path={PATHS.SERVICE_DETAILS} component={ServiceDetails} />
        <Route exact path={PATHS.ADD_SERVICE} component={AddService} />
        <Route exact path={PATHS.EDIT_SERVICE} component={EditService} />
        <Route exact path={PATHS.ADD_SERVICE_IMG} component={AddServiceImage} />
        <Route exact path={PATHS.EDIT_SERVICE_IMG} component={AddServiceImage} />

        <Route exact path={PATHS.ADD_SECRETARY} component={AddSecretary} />
        <Route exact path={PATHS.EDIT_SECRETARY} component={AddSecretary} />

        <Route exact path={PATHS.ADD_CLINIC} component={AddClinic} />
        <Route exact path={PATHS.EDIT_CLINIC} component={EditClinic} />
        <Route exact path={PATHS.CLINIC_DETAILS} component={ClinicDetails} />

        <Route exact path={PATHS.TERMS} component={Terms} />
        <Route exact path={PATHS.TECHNICAL_SUPPORT} component={ContactUs} />
        <Route exact path={PATHS.SUBSCRIPTION} component={Subscription} />
    </CommonRouter>
)

export default NotApprovedUserRouter
