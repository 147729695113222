import { lazy } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { PATHS } from '../constants/paths'
import CommonRouter from './common'

const Login = lazy(() => import('../login-forms/login-page/Login'))
const Terms = lazy(() => import('../account-information/legal-terms/terms'))
const AdminLogin = lazy(() => import('../AdminLogin'))
const ResetPassword = lazy(() => import('../login-forms/reset-password/ResetPassword'))
const CompleteProfile = lazy(() => import('../login-forms/complete-profile/CompleteProfile'))
const Loading = lazy(() => import('../Components/loading/Loading'))
const Layout = lazy(() => import('../login-forms/Layout'))
const SignUp = lazy(() => import('../login-forms/signup/SignUp'))

type PublicRouterProps = {
    initialPath: string
}

const PublicRouter = ({ initialPath }: PublicRouterProps) => (
    <CommonRouter initialPath={initialPath}>
        <Route exact path='PATHS.LOADING' component={() => <Loading />} />
        <Route
            exact
            path={PATHS.HOME}
            component={() => (
                <Layout img='/assets/login-forms/Online Doctor-pana.svg' render={<Login />} />
            )}
        />
        <Route
            exact
            path={PATHS.SING_UP}
            component={() => (
                <Layout img='/assets/login-forms/Doctors-bro.svg' render={<SignUp />} />
            )}
        />
        <Route
            exact
            path={PATHS.COMPLETE_PROFILE}
            component={() => (
                <Layout img='/assets/login-forms/Doctor-pana.svg' render={<CompleteProfile />} />
            )}
        />
        <Route
            exact
            path={PATHS.RESET_PASSWORD}
            component={() => (
                <Layout
                    img='/assets/login-forms/Forgot password-bro.svg'
                    render={<ResetPassword />}
                />
            )}
        />
        <Route
            exact
            path={PATHS.ADMIN}
            component={() => (
                <Layout img='/assets/login-forms/Forgot password-bro.svg' render={<AdminLogin />} />
            )}
        />
        <Route exact path={PATHS.LEGAL_TERMS} component={Terms} />
        <Route component={() => <Redirect to={PATHS.HOME} />} />
        <Redirect to={initialPath} />
    </CommonRouter>
)

export default PublicRouter
