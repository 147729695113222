import React from 'react'
import { useHistory } from 'react-router-dom'

export default ({ event, status }) => {
    const { item } = event

    return (
        <div className={`event-container ${status === 1 ? 'active' : ''} `}>
            {item.type === 'CLINIC' ? <p>{item.clinic}</p> : ''}
            {item.type === 'ONLINE' ? <p>Online</p> : ''}
            {item.type === 'BOTH' ? <p>{`${item.clinic} , Online `}</p> : ''}
        </div>
    )
}

export const EventWrapper = ({ event, children }) => {
    const { className, style } = children.props
    const { item } = event
    const history = useHistory()
    const customClass = `${className} rbc-event-${item.active === 1 ? 'active' : 'inactive'}`

    return (
        <div
            className={customClass}
            style={style}
            onClick={() => history.push(`/duration-details/${item.id}`)}
        >
            {children.props.children}
        </div>
    )
}
