export const PATHS = {
    // No user
    HOME: '/',
    SING_UP: '/signUp',
    COMPLETE_PROFILE: '/completeProfile',
    RESET_PASSWORD: '/resetPassword',
    ADMIN: '/a/d/m/i/n',
    LEGAL_TERMS: '/Legal terms',
    LOADING: '/loading',
    // is there a user
    DURATIONS: '/Durations',
    ADD_DURATION: '/AddDuration',
    DURATION_DETAILS: '/duration-details/:durationId',
    ACCOUNTING_INFO: '/AccountInfo/:currentTab',
    ADD_SERVICE: '/AddService',
    EDIT_SERVICE: '/EditService/:serviceId',
    EDIT_SERVICE_IMG: '/EditServiceImg/:serviceId/:serviceImageId',
    ADD_SERVICE_IMG: '/AddServiceImg/:serviceId',
    ADD_SECRETARY: '/AddSecretary',
    EDIT_SECRETARY: '/edit-secretary/:secretaryUid',
    ADD_CLINIC: '/AddClinic',
    EDIT_CLINIC: '/EditClinic/:customClinicId',
    CLINIC_DETAILS: '/ClinicDetails/:clinicId',
    TERMS: '/Terms',
    TECHNICAL_SUPPORT: '/technical-support',
    SUBSCRIPTION: '/subscription',
    BLOGS: '/Blogs',
    ADD_NEW_BLOG: '/AddNewBlog',
    BLOG_DETAILS: '/blog/:blogId',
    MESSAGE: '/Messages/:patientId?',
    EARNINGS: '/Earnings',
    PROMO_CODE: '/PromoCode',
    ADD_PROMO_CODE: '/AddPromoCode',
    PATIENTS_LIST: '/PatientsList',
    PATIENT_DETAILS: '/patient/:patientId',
    REGISTER_PATIENT: '/RegisterPatient',
    BOOK_NEW: '/BookNew/:patientId?/:patientName?',
    ADD_MEDICAL_RECORD: '/AddMedicalRecord/:patientUid',
    VIEW_MEDICAL_RECORD: '/ViewMedicalRecord/:patientId',
    FAV: '/Favorites',
    SERVICE_DETAILS: '/ServiceDetails/:serviceId',
    SUBSCRIPTIONS: '/subscriptions/payment-history/:doctorUid/:planId?'
}
